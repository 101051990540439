import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import FullWidthImage from "../../components/full_width_image";

export const query = graphql`
  query StoryQuery($slug: String!) {
    strapiStory(slug: { eq: $slug }) {
      strapiId
      slug
      title
      description
      publishedAt
      content {
        __typename
        ... on StrapiComponentTextTextSection {
          title
          text
        }
        ... on StrapiComponentImageFullWidthImage {
          image {
            caption
            alternativeText
            file {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH,
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
        ... on StrapiComponentImageFixedImageGrid {
          images {
            file {
              publicURL
            }
          }
        }
      }
      cover {
        image {
          file {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH,
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
      }
    }
  }
`

const Story = ({ data }) => {
  const story = data.strapiStory
  const seo = {
    title: story.title,
    description: story.description,
    shareImage: story.cover.image,
    article: false,
  }
  const page = `/story/${story.slug}`

  return (
    <Layout seo={seo} page={page}>
      <div>
        {story.content.map((dynamicComponent, i) => {

          if (dynamicComponent.__typename === "StrapiComponentImageFullWidthImage") {
            return (
              <FullWidthImage image={dynamicComponent.image} />
            );
          } else {
            return (
              <p>{dynamicComponent.__typename}</p>
            );
          }
        })}
      </div>
    </Layout>
  )
}

export default Story