import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { description, excerpt, fullWidthImage } from "./full_width_image.module.css"

const FullWidthImage = ({ image }) => (
  <div className={fullWidthImage}>
    <GatsbyImage
      image={image.file.childImageSharp.gatsbyImageData}
      alt={image.alternativeText} />
    <div className={description}>
      <p className={excerpt}>{image.caption}</p>
    </div>
  </div>
)

export default FullWidthImage;